<template>
	<div class="slideshow-sticky-post">
		<swiper ref="swiperSticky" :class="backgroundConverted" :options="swiperOption" @slideChange="onSlideChange">
			<swiper-slide class="swiperStickySlide" v-for="(data, i) in stickyData" :key="i">
				<MainColumn class="container">
					<v-row class="slide">
						<v-col
							offset="1"
							cols="4"
							style="cursor: pointer"
							@click="onNewsClick(data.mainCategory, data.uri)"
						>
							<div class="verticalText d-inline-block">
								<div class="d-inline-block font-size-12">
									{{ $t("sticky") }}
								</div>
								<v-icon color="white" class="pt-2 pr-2">
									mdi-asterisk
								</v-icon>
							</div>
							<ImageShape
								class="imageShape"
								:category="data.mainCategory.slug"
								:srcImage="data.featuredImage.node.sourceUrl"
								:maxHeight="350"
							/>
						</v-col>
						<v-col
							offset="1"
							cols="6"
							style="cursor: pointer"
							@click="onNewsClick(data.mainCategory, data.uri)"
						>
							<v-row v-if="data.target.name">
								<span class="target">{{ data.target.name }}</span>
							</v-row>
							<v-row v-if="data.title">
								<h2 class="font-size-40">{{ data.title }}</h2>
							</v-row>
							<v-row v-if="selectedInfo.subtitle">
								<p class="font-size-35">
									{{ selectedInfo.subtitle }}
								</p>
							</v-row>
							<v-row v-if="selectedInfo.buttonUrl">
								<v-btn
									name="Recensione"
									class="infoButton"
									color="white"
									depressed
									:style="{
										color: colorsObject[target],
									}"
									@click="openLinkInNewTab(selectedInfo.buttonUrl)"
									>{{ selectedInfo.buttonLabel }}</v-btn
								>
							</v-row>
						</v-col>
					</v-row>
				</MainColumn>
			</swiper-slide>
		</swiper>
		<PaginationSlideshow
			class="pagination"
			slot="pagination"
			:currentIndex="currentSlideId + 1"
			:numberOfPosts="stickyData.length"
			:target="target"
			@onPaginationClick="onPaginationClick"
		></PaginationSlideshow>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ImageShape from "@/components/ui/ImageShape.vue";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";
export default {
	props: {
		target: {
			type: String,
			required: true,
		},
		stickyData: {
			type: Array,
			required: true,
		},
	},
	components: {
		ImageShape,
		PaginationSlideshow,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			currentSlideId: 0,
			swiperOption: {
				slidesPerView: "1",
				spaceBetween: 0,
				centeredSlides: true,
				autoplay: {
					delay: 5000,
				},
			},
		};
	},
	computed: {
		backgroundConverted() {
			if (
				this.target === "bambini-e-famiglie" ||
				this.target === "ragazzi" ||
				this.target === "adulti" ||
				this.target === "scuole" ||
				this.target === "tutti"
			) {
				return "background-" + this.slugConverter[this.target];
			} else {
				return "background-" + this.target;
			}
		},
		swiper() {
			return this.$refs.swiperSticky.$swiper;
		},
		selectedInfo() {
			let subtitle = "";
			let currentData = this.stickyData[this.currentSlideId];
			if (!currentData || !currentData.fields) {
				return {};
			}
			switch (currentData.mainCategory.slug) {
				case "articoli":
					subtitle = currentData.fields.subtitle_article || "";
					break;
				case "novita":
					subtitle = currentData.fields.subtitle_news || "";
					break;
				case "recensioni":
					subtitle = currentData.fields.subtitle_review || "";
					break;
				case "reportages":
					subtitle = currentData.fields.subtitle_reportage || "";
					break;
				default:
					subtitle = "";
			}
			return {
				subtitle: subtitle,
				buttonUrl: currentData.fields.button_url || "",
				buttonLabel: currentData.fields.button_label || "",
			};
		},
	},
	methods: {
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		onSlideChange() {
			this.currentSlideId = this.swiper.activeIndex;
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination {
	position: absolute;
	bottom: 1rem;
}
.slideshow-sticky-post {
	position: relative;
}
.container {
	position: relative;
}
.infoButton {
	border-radius: 50px 50px 50px 5px;
	margin-top: $spacing-0;
}
.swiperStickySlide {
	height: 80%;
	padding: $spacing-1 0;
}
.target {
	font-size: $font-size-14;
	margin-bottom: $spacing-0;
	text-transform: uppercase;
}
.verticalText {
	left: 0;
	top: 0;
	position: absolute;
	transform: rotate(-180deg);
	text-transform: uppercase;
	writing-mode: tb-rl;
}

@media only screen and (max-width: 768px) {
	.slide {
		left: 50%;
		transform: translateX(-50%);
	}
	.verticalText {
		display: none;
	}
	.row,
	.col {
		position: relative;
		display: inline-block;
		width: 100%;
		max-width: 500px;
		margin: auto;
	}

	.imageShape {
		position: relative;
		width: 70%;
		max-height: 320px;
		max-width: 320px;
		margin: auto;
		margin-bottom: 1rem;
	}
}
</style>
