<template>
	<div class="subpages">
		<div
			v-for="(children, i) in childrens"
			:class="{
				active: activeTab === i,
				'button-tab': true,
				['background-' + children.slug]: !selectedSlug && children.slug && activeTab === i,
				['background-' + slugConverter[children.slug]]: !selectedSlug && children.slug && activeTab === i,
				[`background-${selectedSlug}`]: selectedSlug && activeTab === i,
				'background-default': activeTab !== i,
				'font-size-12': true,
			}"
			:key="i"
			:style="{
				width: 'auto',
				height: calculateWidthColumn(1),
				'line-height': calculateWidthColumn(1),
			}"
			@click="$emit('update:activeTab', i)"
		>
			{{ children.title }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		activeTab: {
			type: Number,
			required: true,
		},
		childrens: {
			type: Array,
			required: true,
		},
		selectedSlug: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			width: 0,
		};
	},
};
</script>
<style lang="scss" scoped>
.tab.active {
	display: block;
	font-weight: bold;
}

.subpages {
	display: inline-flex;
	justify-content: center;
	min-width: 768px;
	width: 50%;
	max-width: 90%;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.button-tab {
	flex-grow: 4;
	border-radius: 14px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	float: left;
	text-align: center;
	text-transform: uppercase;
	padding: 0 25px;
}

.background-default {
	background: #f1f1f1;
}
.background-all {
	background: $blue;
}

.button-tab:hover {
	cursor: pointer;
}
@media only screen and (max-width: 768px) {
	.subpages {
		width: 100%;
		min-width: auto;
	}
	.button-tab {
		font-size: $font-size-16;
		padding: 0 15px;
	}
}

@media only screen and (max-width: 550px) {
	.subpages {
		max-width: 100%;
	}
	.button-tab {
		font-size: $font-size-12;
		padding: 0 10px;
	}
}

@media only screen and (max-width: 440px) {
	.button-tab {
		font-size: 10px;
		padding: 0 5px;
	}
}
</style>
