var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subpages"},_vm._l((_vm.childrens),function(children,i){return _c('div',{key:i,class:{
			active: _vm.activeTab === i,
			'button-tab': true,
			['background-' + children.slug]: !_vm.selectedSlug && children.slug && _vm.activeTab === i,
			['background-' + _vm.slugConverter[children.slug]]: !_vm.selectedSlug && children.slug && _vm.activeTab === i,
			[`background-${_vm.selectedSlug}`]: _vm.selectedSlug && _vm.activeTab === i,
			'background-default': _vm.activeTab !== i,
			'font-size-12': true,
		},style:({
			width: 'auto',
			height: _vm.calculateWidthColumn(1),
			'line-height': _vm.calculateWidthColumn(1),
		}),on:{"click":function($event){return _vm.$emit('update:activeTab', i)}}},[_vm._v(" "+_vm._s(children.title)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }