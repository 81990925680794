var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Background',{staticClass:"background",style:({ height: _vm.calculateWidthColumn(7) }),attrs:{"backgroundColor":"#fff","color":"rgba(0,0,0,0.2)"}},[_c('Loader',{attrs:{"template":"tiny-fullpage","status":_vm.loading}}),_c('PatternShapes',{staticClass:"pattern-shapes",style:({
				height: _vm.calculateWidthColumn(7),
			}),attrs:{"pattern":"Archives"}}),_c('div',{style:({
				height: _vm.calculateWidthColumn(7),
			})},[_c('MainColumn',{style:({ height: _vm.calculateWidthColumn(2) })},[_c('MovingLogo',{attrs:{"logoPositions":_vm.logoPositions,"offset":0,"target":'default',"width":_vm.mainColumnWidth,"color":"black"}})],1),_c('swiper',{ref:"swiper",staticClass:"swipe",style:({
					height: _vm.calculateWidthColumn(5),
				}),attrs:{"options":_vm.swiperOption},on:{"slideChange":_vm.onSlideChange}},_vm._l((_vm.slides),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({ width: slide.width + 'px' })},[_c('h2',{staticClass:"title_swiper font-size-100",style:({
							'line-height': _vm.calculateWidthColumn(3),
						}),on:{"click":function($event){return _vm.swiper.slideTo(i)}}},[_vm._v(" "+_vm._s(slide.name)+" ")])])}),1),(_vm.targets)?_c('Tabs',{style:({
					height: _vm.calculateWidthColumn(1),
					'z-index': 2,
				}),attrs:{"activeTab":_vm.activeTab,"childrens":_vm.targets,"page":_vm.page},on:{"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event}}}):_vm._e()],1)],1),_c('v-fade-transition',[(_vm.currentStickyData && _vm.currentStickyData.length > 0)?_c('SlideshowStickyPost',{ref:"slideshowStickyPost",attrs:{"stickyData":_vm.currentStickyData,"target":_vm.targets && _vm.targets[_vm.activeTab] ? _vm.targets[_vm.activeTab].slug : 'all'}}):_vm._e()],1),(_vm.targets)?_c('div',{class:'background-content background-light-' + _vm.targets[_vm.activeTab].slug},[_c('MainColumn',[_c('div',{staticClass:"content"},_vm._l((_vm.currentNotStickyData),function(data,i){return _c('ArticleCard',{key:i,staticClass:"col-content",attrs:{"bottomAlign":true,"image":data.featuredImage ? data.featuredImage.node.sourceUrl : '',"isSubheaderVisible":true,"mainCategory":data.mainCategory,"subCategory":data.subCategory,"subtitle":data.author,"target":data.target,"title":data.title,"whiteText":_vm.targets[_vm.activeTab].slug !== 'all'},nativeOn:{"click":function($event){return _vm.onNewsClick(data.mainCategory, data.uri)}}})}),1),(!_vm.pageInfo || _vm.pageInfo.hasNextPage)?_c('div',{staticClass:"loadMore",on:{"click":_vm.loadMore}},[_c('Button',{attrs:{"border":"black","value":"Carica più articoli"}})],1):_vm._e()])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }