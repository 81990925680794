<template>
	<div>
		<Background
			class="background"
			backgroundColor="#fff"
			color="rgba(0,0,0,0.2)"
			:style="{ height: calculateWidthColumn(7) }"
		>
			<Loader template="tiny-fullpage" :status="loading" />
			<PatternShapes
				class="pattern-shapes"
				pattern="Archives"
				:style="{
					height: calculateWidthColumn(7),
				}"
			/>
			<div
				:style="{
					height: calculateWidthColumn(7),
				}"
			>
				<MainColumn :style="{ height: calculateWidthColumn(2) }">
					<MovingLogo
						:logoPositions="logoPositions"
						:offset="0"
						:target="'default'"
						:width="mainColumnWidth"
						color="black"
					/>
				</MainColumn>
				<swiper
					class="swipe"
					ref="swiper"
					:options="swiperOption"
					@slideChange="onSlideChange"
					:style="{
						height: calculateWidthColumn(5),
					}"
				>
					<swiper-slide
						v-for="(slide, i) in slides"
						class="slide"
						:key="i"
						:style="{ width: slide.width + 'px' }"
					>
						<h2
							class="title_swiper font-size-100"
							:style="{
								'line-height': calculateWidthColumn(3),
							}"
							@click="swiper.slideTo(i)"
						>
							{{ slide.name }}
						</h2>
					</swiper-slide>
				</swiper>
				<Tabs
					v-if="targets"
					:activeTab.sync="activeTab"
					:childrens="targets"
					:page="page"
					:style="{
						height: calculateWidthColumn(1),
						'z-index': 2,
					}"
				/>
			</div>
		</Background>

		<v-fade-transition>
			<SlideshowStickyPost
				ref="slideshowStickyPost"
				v-if="currentStickyData && currentStickyData.length > 0"
				:stickyData="currentStickyData"
				:target="
					targets && targets[activeTab] ? targets[activeTab].slug : 'all'
				"
			/>
		</v-fade-transition>
		<div
			v-if="targets"
			:class="'background-content background-light-' + targets[activeTab].slug"
		>
			<MainColumn>
				<div class="content">
					<ArticleCard
						v-for="(data, i) in currentNotStickyData"
						class="col-content"
						:bottomAlign="true"
						:image="data.featuredImage ? data.featuredImage.node.sourceUrl : ''"
						:isSubheaderVisible="true"
						:key="i"
						:mainCategory="data.mainCategory"
						:subCategory="data.subCategory"
						:subtitle="data.author"
						:target="data.target"
						:title="data.title"
						:whiteText="targets[activeTab].slug !== 'all'"
						@click.native="onNewsClick(data.mainCategory, data.uri)"
					/>
				</div>
				<div
					class="loadMore"
					@click="loadMore"
					v-if="!pageInfo || pageInfo.hasNextPage"
				>
					<Button border="black" value="Carica più articoli" />
				</div>
			</MainColumn>
		</div>
	</div>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: "error"*/
import ArticleCard from "@/components/ui/ArticleCard.vue";
import Background from "@/components/ui/Background";
import Loader from "@/components/ui/Loader";
import Button from "@/components/ui/Button";
import MovingLogo from "@/components/ui/MovingLogo.vue";
import PatternShapes from "@/components/ui/PatternShapes";
import SlideshowStickyPost from "@/components/ui/SlideshowStickyPost";
import Tabs from "@/components/ui/Tabs";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import {
	GET_CATEGORIES,
	GET_POSTS_BY_QZR_TARGET_WITH_STICKY,
	GET_POSTS_BY_QZR_TARGET_ALL_CATEGORIES,
	GET_LAST_POSTS_WITH_STICKY,
} from "@/queries/Posts.js";
import { GET_QZR_TARGETS } from "@/queries/Pages.js";
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
export default {
	metaInfo() {
		if (this.seo) {
			return {
				title: this.seo.title,
				meta: [
					{
						name: "description",
						content: this.seo.metaDesc,
					},
					{
						name: "keywords",
						content: this.seo.metaKeywords
							? this.seo.metaKeywords
							: this.defaultMetaKeywords,
					},
					{
						name: "author",
						content: this.seo.opengraphSiteName,
					},
					{
						property: "og:title",
						content: this.seo.title,
					},
					{
						property: "og:type",
						content: this.seo.opengraphType,
					},
					{
						property: "og:url",
						content: this.seo.opengraphUrl,
					},
					{
						property: "og:description",
						content: this.seo.metaDesc,
					},
					{
						property: "og:image",
						content: this.seo.opengraphImage
							? this.seo.opengraphImage.sourceUrl
							: this.defaultUrlImage,
					},
					{ name: "robots", content: "index,follow" },
				],
			};
		}
	},
	title: "Notizie",
	mixins: [formatCategoriesAndTargets],
	components: {
		Button,
		ArticleCard,
		Background,
		MovingLogo,
		SlideshowStickyPost,
		Swiper,
		SwiperSlide,
		Tabs,
		PatternShapes,
		Loader,
	},
	data() {
		return {
			seo: null,
			activeTab: 0,
			childrens: null,
			currentSlideId: 0,
			loading: true,
			currentNotStickyData: [],
			currentStickyData: null,
			loadedData: [],
			postPerPage: 12,
			pageInfo: false,
			offset: null,
			logoPositions: [
				{
					left: 0,
					top: 1,
					min: { x: 0, y: 1 },
					max: { x: 5, y: 1 },
				},
				{
					left: 6,
					top: 1,
					min: { x: 6, y: 1 },
					max: { x: 10, y: 1 },
				},
				{
					left: 11,
					top: 1,
					min: { x: 11, y: 1 },
					max: { x: 15, y: 1 },
				},
				{
					left: 16,
					top: 1,
					min: { x: 16, y: 1 },
					max: { x: 20, y: 1 },
				},
				{
					left: 21,
					top: 1,
					min: { x: 21, y: 1 },
					max: { x: 25, y: 1 },
				},
			],
			page: null,
			slides: [],
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 0,
				centeredSlides: true,
			},
			targets: null,
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		},
	},
	watch: {
		currentSlideId: async function(val) {
			this.loadNewData(val, this.activeTab);
		},
		activeTab: async function(val) {
			this.loadNewData(this.currentSlideId, val);
			this.offset = false;
			this.currentNotStickyData = [];
		},
	},
	async mounted() {
		this.loadStart();
		let query = {
			query: GET_CATEGORIES(),
		};

		let slides = await this.$apollo.query(query);

		slides = slides.data.categories.nodes;
		if (slides[0].name !== "Notizie") {
			slides.unshift({
				name: "Notizie",
				slug: "notizie",
			});
		}
		slides.map((slide) => {
			slide.width =
				window.innerWidth > 768
					? this.getTextWidth("110px", slide.name)
					: window.innerWidth > 550
					? this.getTextWidth("100px", slide.name)
					: this.getTextWidth("50px", slide.name);
		});

		slides = slides.filter(
			(slide) =>
				slide.slug !== "libri" &&
				slide.slug !== "film" &&
				slide.slug !== "musica" &&
				slide.slug !== "senza-categoria"
		);
		this.slides = slides;
		let selectedCategory = this.$route.params.category;
		if (selectedCategory) {
			let index = this.slides.findIndex(
				(slide) => slide.slug === selectedCategory
			);
			this.currentSlideId = index;
			this.swiper.slideTo(index);
		}

		query = {
			query: GET_QZR_TARGETS(),
		};
		let targets = await this.$apollo.query(query);
		targets = targets.data.pages.nodes;
		targets = targets.filter((target) => target.slug !== "tutti");
		targets.unshift({
			title: "Tutti",
			slug: "tutti",
		});
		targets.map((target) => {
			if (this.slugConverter[target.slug]) {
				target.slug = this.slugConverter[target.slug];
			}
		});
		this.targets = targets;
		let params = this.$route.path.split("/");
		let selectedTarget = this.slugConverter[params[3]] || null;
		if (selectedTarget) {
			let index = this.targets.findIndex(
				(target) => target.slug === selectedTarget
			);
			this.activeTab = index;
		}
		await this.loadNewData(this.currentSlideId, this.activeTab);
		this.loadDone();
	},
	methods: {
		async loadMore() {
			this.offset = this.pageInfo.endCursor;
			this.loadNewData(this.currentSlideId, this.activeTab);
		},
		onSlideChange() {
			this.offset = false;
			this.currentStickyData = [];
			this.currentNotStickyData = [];
			this.currentSlideId = this.swiper.activeIndex;
		},
		async loadNewData(currentSlideId, activeTab) {
			let category =
				currentSlideId && this.slides[currentSlideId]
					? this.slides[currentSlideId].slug
					: "notizie";
			let target = activeTab ? this.targets[activeTab].slug : "all";

			if (!this.loadedData[category] || !this.loadedData[category][target]) {
				if (target !== "all") {
					this.buildQuerySpecificTarget(category, target);
				} else {
					this.buildQueryAllTargets(category);
				}
			} else {
				this.currentNotStickyData = this.loadedData[category][target];
			}

			setTimeout(
				function() {
					this.loading = false;
				}.bind(this),
				500
			);
		},
		async buildQueryAllTargets(category) {
			let condition =
				category === "notizie" ? "" : 'categoryName: "' + category + '"';
			let query = {
				query: GET_LAST_POSTS_WITH_STICKY(
					condition,
					this.postPerPage,
					this.offset
				),
			};
			let loadedData = await this.$apollo.query(query);
			this.pageInfo = loadedData.data
				? loadedData.data.notSticky.pageInfo
				: false;

			if (loadedData.data) {
				let loadedNotStickyData = loadedData.data.notSticky.nodes;
				this.currentNotStickyData = this.currentNotStickyData.concat(
					this.formatCategoriesAndTargets(loadedNotStickyData)
				);
				let loadedStickyData = loadedData.data.sticky.nodes;
				this.currentStickyData = this.formatCategoriesAndTargets(
					loadedStickyData
				);
				this.loadDone();
				setTimeout(
					function() {
						this.loading = false;
					}.bind(this),
					500
				);
			}
		},
		async buildQuerySpecificTarget(category, target) {
			if (
				target === "bambini-e-famiglie" ||
				target === "ragazzi" ||
				target === "adulti" ||
				target === "scuole" ||
				target === "tutti"
			) {
				target = this.slugConverter[target];
			}
			let query = {
				query:
					category === "notizie"
						? GET_POSTS_BY_QZR_TARGET_ALL_CATEGORIES(
								target,
								this.postPerPage,
								this.offset
						)
						: GET_POSTS_BY_QZR_TARGET_WITH_STICKY(
								target,
								category,
								this.postPerPage,
								this.offset
						),
			};
			let loadedData = await this.$apollo.query(query);
			if (
				loadedData.data.qzrTargets &&
				loadedData.data.qzrTargets.nodes &&
				loadedData.data.qzrTargets.nodes[0]
			) {
				let loadedNotStickyData =
					loadedData.data.qzrTargets.nodes[0].notSticky.nodes;
				this.currentNotStickyData = this.currentNotStickyData.concat(
					this.formatCategoriesAndTargets(loadedNotStickyData)
				);
				let loadedStickyData = loadedData.data.qzrTargets.nodes[0].sticky.nodes;
				this.currentStickyData = this.formatCategoriesAndTargets(
					loadedStickyData
				);
				this.pageInfo = loadedData.data.qzrTargets.nodes[0].notSticky.pageInfo
					? loadedData.data.qzrTargets.nodes[0].notSticky.pageInfo
					: false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.loadMore {
	text-align: center;
	display: inline-block;
	width: 100%;
	font-size: 17px;
	margin-bottom: 50px;
	cursor: pointer;
}
.pattern-shapes {
	overflow: hidden;
}
.background {
	width: 100vw;
	position: relative;
}
.background-content {
	position: relative;
	transition: all 0.3;
	z-index: 3;
}
.background-all {
	background: white;
	color: black;
}
.content {
	padding: $spacing-1 0;
	padding-bottom: 0;
	display: inline-flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 100%;
	overflow: hidden;
}
.col-content {
	cursor: pointer;
	position: relative;
	padding: 0 $spacing-0;
	width: 33%;
	margin-bottom: $spacing-1;
}

.col-content:nth-child(1n) {
	padding-left: 0;
}
.col-content:nth-child(3n + 0) {
	padding-right: 0;
}

.swiper-slide-active {
	opacity: 1 !important;
}

.slide {
	opacity: 0.3;
	transition: 0.6s opacity;
	text-align: center;
}

.title_swiper {
	display: inline-block;
	color: black;
	cursor: pointer;
}

@media only screen and (max-width: 1024px) {
	.article-card {
		width: 50%;
		margin-bottom: 0;
		padding: $spacing-0 !important;
	}
}
@media only screen and (max-width: 550px) {
	.article-card {
		width: 100%;
	}
}
</style>
